import React from "react";
import {styled} from "@mui/material/styles";
import {
    ContentBody,
    ContentHeader,
    ContentSubHeader,
    ContentTitle,
    ContentTitleWrapper,
    ContentWrapper,
    IconBackground,
    Line,
    RootStyle
} from "../component";
import {Box, Container, Grid} from "@mui/material";
import infoImage from "../../../assets/img/explore.png";
import infoImageDark from "../../../assets/img/exploreDark.png";
import {Icon} from "@iconify/react";
import {MotionInView, varFadeInLeft, varFadeInRight} from "../animate";
import useSettings from "../../../hooks/useSettings";

const Info = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "dark";

    return (
        <RootStyle id="features">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <MotionInView variants={varFadeInLeft}>
                            <ContentWrapper>
                                <ContentTitleWrapper>
                                    <Line />
                                    <ContentTitle>PAA MARKET</ContentTitle>
                                </ContentTitleWrapper>
                                <ContentHeader>
                                    Introducing the PAA Marketplace
                                </ContentHeader>
                                <ContentSubHeader>
                                    PAA supports virtually almost all local
                                    currencies (FIAT) in the world with Bank
                                    Account listing for both local withdrawals
                                    and deposit, which is tradeable with the
                                    supported Cryptocurrencies in the system.
                                    Exchange rates for each local currencies can
                                    both be manually set or automatically
                                    sourced from a trusted exchange rates
                                    provider.
                                </ContentSubHeader>
                            </ContentWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        sx={{paddingTop: 10}}>
                        <Grid item xs={12} md={8}>
                            <MotionInView variants={varFadeInLeft}>
                                <ImageWrapper>
                                    {isLight ? (
                                        <Image
                                            src={infoImage}
                                            alt="pc-tab-phone"
                                        />
                                    ) : (
                                        <Image
                                            src={infoImageDark}
                                            alt="pc-tab-phone"
                                        />
                                    )}
                                </ImageWrapper>
                            </MotionInView>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {infoItems.map((infoItem) => (
                                <MotionInView
                                    variants={varFadeInRight}
                                    key={infoItem.id}>
                                    <InfoWrapper>
                                        <IconBackground
                                            sx={{height: 40, width: 40}}>
                                            <InfoIcon icon="typcn:tick" />
                                        </IconBackground>
                                        <ContentBody sx={{marginLeft: "25px"}}>
                                            {infoItem.text}
                                        </ContentBody>
                                    </InfoWrapper>
                                </MotionInView>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Info;

const ImageWrapper = styled(Box)(({theme}) => ({
    maxWidth: "100%",
    marginRight: "10%",
    [theme.breakpoints.down("md")]: {
        margin: "0 auto",
        paddingBottom: 40
    }
}));
const Image = styled("img")(() => ({
    width: "100%"
}));
const InfoWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: `2px solid ${theme.palette.primary.light}`,
    padding: "20px",
    borderRadius: theme.shape.borderRadiusMd,
    marginBottom: 10,
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("md")]: {
        marginLeft: 0
    },
    "&:hover": {
        border: `2px solid ${theme.palette.primary.lighter}`,
        cursor: "pointer",
        transform: "translateX(-15px)",
        transition: ".4s all ease"
    }
}));
const InfoIcon = styled(Icon)(() => ({
    fontSize: 25,
    color: "#fff"
}));

const infoItems = [
    {
        id: 1,
        text: "Safe & Secure Transactions."
    },
    {
        id: 2,
        text: "Easily Customisable"
    },
    {
        id: 3,
        text: "Multiple Payment Gateway"
    },
    {
        id: 4,
        text: "KYC Verification"
    }
];
