import React from "react";
import {styled} from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from "@mui/material";
import {
    ContentHeader,
    ContentSubHeader,
    ContentTitle,
    ContentTitleWrapper,
    ContentWrapper,
    IconBackground,
    Line,
    PageLink,
    RootStyle
} from "../component";
import {Icon} from "@iconify/react";
import {MotionInView, varFadeInLeft, varFadeInUp} from "../animate";

const Features = () => {
    return (
        <RootStyle id="features" sx={{background: "#eff6ff"}}>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <MotionInView variants={varFadeInLeft}>
                            <ContentWrapper sx={{paddingBottom: 0}}>
                                <ContentTitleWrapper>
                                    <Line />
                                    <ContentTitle>How it works</ContentTitle>
                                </ContentTitleWrapper>
                                <ContentHeader sx={{color: "#000"}}>
                                    Features
                                </ContentHeader>
                                <ContentSubHeader>
                                    Localcointrade is the most popular
                                    non-custodial cryptocurrency marketplace
                                    that enables you to buy bitcoins and trade
                                    the most cryptocurrencies.
                                </ContentSubHeader>
                            </ContentWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid container item>
                        {cardItems.map((cardItem) => (
                            <Grid item key={cardItem.id} xs={12} md={4}>
                                <MotionInView variants={varFadeInUp}>
                                    <FeatureCard>
                                        <IconWrapper>
                                            <IconBackground>
                                                <CardIcon
                                                    icon={cardItem.icon}
                                                />
                                            </IconBackground>
                                        </IconWrapper>
                                        <CardWrapper>
                                            <CardTitle>
                                                {cardItem.header}
                                            </CardTitle>
                                            <CardLine />
                                            <CardBody>{cardItem.text}</CardBody>
                                        </CardWrapper>
                                    </FeatureCard>
                                </MotionInView>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item>
                        <MotionInView variants={varFadeInUp}>
                            <ButtonWrapper>
                                <PageLink href="/auth/login">
                                    <FeatureButton variant="outlined">
                                        Try It Out
                                    </FeatureButton>
                                </PageLink>
                                <PageLink href="/auth/login">
                                    <FeatureButton
                                        variant="contained"
                                        sx={{marginLeft: 2}}>
                                        Explore Features
                                    </FeatureButton>
                                </PageLink>
                            </ButtonWrapper>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Features;

const IconWrapper = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative"
}));
const CardIcon = styled(Icon)(() => ({
    fontSize: 30,
    color: "#fff"
}));
const FeatureCard = styled(Card)(({theme}) => ({
    zIndex: 1,
    position: "relative",
    margin: "35px 30px 50px",
    padding: "70px 0px 20px",
    height: 360,
    borderRadius: 16,
    boxShadow: theme.shadows[2],
    "&:hover": {
        transform: "scale(1.05)",
        cursor: "pointer",
        boxShadow: theme.customShadows.primary,
        transition: "0.4s all ease"
    },
    [theme.breakpoints.down("lg")]: {
        height: "400px"
    },
    [theme.breakpoints.down("md")]: {
        padding: "60px 0px ",
        height: "100%"
    }
}));
const CardWrapper = styled(CardContent)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}));
const CardTitle = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    fontWeight: 500,
    paddingTop: 10,
    textAlign: "center",
    color: theme.palette.text.primary
}));
const CardLine = styled(Box)(({theme}) => ({
    height: 1.3,
    width: 40,
    margin: "5px 0px",
    background: theme.palette.primary.main
}));
const CardBody = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    padding: "12px 0 0",
    textAlign: "center",
    color: theme.palette.grey["500"]
}));
const ButtonWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center"
}));
const FeatureButton = styled(Button)(() => ({
    padding: "8px 22px",
    height: 48
}));
const cardItems = [
    {
        id: 1,
        header: "Accessibility",
        text: "The ecosystem is easily accessible and user friendly. The ecosystem has an enhanced performance to ensure smooth and fast transactions.",
        icon: "ri:thunderstorms-fill"
    },
    {
        id: 2,
        header: "Secured",
        text: "Secured transactions coupled with 24/7 technical support makes the system the most secure place to trade on",
        icon: "ant-design:safety-certificate-filled"
    },
    {
        id: 3,
        header: "Reliable Cost",
        text: "With the most secure wallet systems, paa users are entitled to unique transactional addresses.",
        icon: "wpf:bank-cards"
    }
];
