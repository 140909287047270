import React from "react";
import {styled} from "@mui/material/styles";
import {
    Box,
    Button,
    CardActionArea,
    FormControlLabel,
    IconButton,
    List,
    Radio,
    RadioGroup,
    Toolbar
} from "@mui/material";
import {animateScroll as scroll, Link as ScrollLink} from "react-scroll";
import {navItems} from "./data";
import {Icon} from "@iconify/react";
import {PageLink} from "../component";
import sunFill from "@iconify-icons/eva/sun-fill";
import moonFill from "@iconify-icons/eva/moon-fill";
import LogoSvg from "../../../assets/img/paaCrypto.png";
import useSettings from "../../../hooks/useSettings";

const Header = ({scrollNav, toggle, isOpen}) => {
    const {themeMode, onChangeMode} = useSettings();
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <Navbar disableGutters isopen={isOpen} scrollNav={scrollNav}>
            <LogoContainer onClick={toggleHome}>
                <Logo src={LogoSvg} alt="logo" />
            </LogoContainer>
            <Box sx={{flexGrow: 0.5}}></Box>
            <Box sx={{display: {xs: "none", md: "flex"}}}>
                <NavItemWrapper>
                    <NavLinksContainer>
                        {navItems.map((navItem) => (
                            <NavLinks
                                key={navItem.id}
                                to={navItem.idm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-50}
                                data-name={navItem.name}>
                                {navItem.name}
                            </NavLinks>
                        ))}
                    </NavLinksContainer>
                    <ThemeContainer>
                        <RadioGroup
                            name="themeMode"
                            onChange={onChangeMode}
                            value={themeMode}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                {["dark", "light"].map((mode, index) => (
                                    <CardActionArea
                                        sx={{color: "primary.main"}}
                                        key={mode}>
                                        <Box
                                            sx={{
                                                py: 4,
                                                display: "flex",
                                                color: "text.disabled",
                                                justifyContent: "center",
                                                ...(themeMode === mode && {
                                                    color: "primary.main"
                                                })
                                            }}>
                                            <Icon
                                                icon={
                                                    index === 0
                                                        ? sunFill
                                                        : moonFill
                                                }
                                                width={24}
                                                height={24}
                                            />
                                        </Box>

                                        <FormControlLabel
                                            label=""
                                            value={mode}
                                            control={
                                                <Radio sx={{display: "none"}} />
                                            }
                                            sx={{
                                                top: 0,
                                                margin: 0,
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute"
                                            }}
                                        />
                                    </CardActionArea>
                                ))}
                            </Box>
                        </RadioGroup>
                    </ThemeContainer>
                    <Box
                        sx={{
                            position: "absolute",
                            right: "10%"
                        }}>
                        <PageLink href="/auth/register">
                            <NavButton variant="outlined">
                                Get Started
                            </NavButton>
                        </PageLink>
                    </Box>
                </NavItemWrapper>
            </Box>
            <Box sx={{display: {xs: "block", md: "none"}}}>
                <NavItemWrapper>
                    <NavIconButton onClick={toggle}>
                        <NavIcon icon="ri:menu-3-fill" />
                    </NavIconButton>
                </NavItemWrapper>
            </Box>
        </Navbar>
    );
};
export default Header;

const Navbar = styled(Toolbar)(({theme, scrollNav}) => ({
    height: "100px",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    background: scrollNav ? theme.palette.background.paper : "transparent",
    boxShadow: scrollNav ? theme.shadows[3] : "none",
    transition: ".35s ease-in-out",
    zIndex: 999
}));
const LogoContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "10%",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
        left: "5%"
    }
}));
const Logo = styled("img")(({theme}) => ({
    height: 80,
    width: 80
}));
const ThemeContainer = styled(Box)(({theme}) => ({
    position: "absolute",
    right: "26%",
    [theme.breakpoints.down("lg")]: {
        right: "30%"
    }
}));
const NavItemWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));
const NavLinksContainer = styled(List)(({theme}) => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
        marginLeft: -50
    }
}));
const NavLinks = styled(ScrollLink)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    padding: "25px 20px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&.active": {
        color: theme.palette.primary.dark,
        fontWeight: "bold"
    },
    "&::before": {
        content: "attr(data-name)",
        position: "absolute",
        width: "0px",
        transition: "all .5s ease-out",
        overflow: "hidden",
        color: theme.palette.primary.light
    },
    "&:hover::before": {
        width: "100%",
        transition: "all .5s ease-in"
    },
    [theme.breakpoints.down("lg")]: {
        padding: "25px 10px"
    }
}));
const NavButton = styled(Button)(({theme}) => ({
    padding: "8px 35px",
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: "#fff",
    transition: ".3s all ease",
    boxShadow: theme.customShadows.primary,
    fontSize: 14,
    "&:hover": {
        background: theme.palette.primary.dark
    }
}));
const NavIconButton = styled(IconButton)(() => ({
    position: "absolute",
    right: "10%"
}));
const NavIcon = styled(Icon)(({theme}) => ({
    fontSize: "30px",
    color: theme.palette.primary.main
}));
