import React from "react";
import Landing from "./landing";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import ThemeConfig from "./theme";

function App() {
    return (
        <ThemeConfig>
            <Router>
                <Switch>
                    <Route path="/">
                        <Landing />
                    </Route>
                </Switch>
            </Router>
        </ThemeConfig>
    );
}

export default App;
