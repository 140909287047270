import React, {useCallback} from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {keyframes} from "@emotion/react";
import {Icon} from "@iconify/react";
import Particles from "react-particles";
import {loadFull} from "tsparticles";
import {OverlayImage, PageLink} from "../component";

const Hero = () => {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);

        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);
    const theme = useTheme();
    return (
        <RootStyle id="home">
            <ParticleStyle
                init={particlesInit}
                loaded={particlesLoaded}
                id="tsparticles"
                options={{
                    fullScreen: {
                        enable: false
                    },
                    fpsLimit: 60,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push"
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse"
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: theme.palette.primary.main,
                        links: {
                            color: theme.palette.primary.light,
                            distance: 200,
                            enable: true,
                            opacity: 0.5,
                            width: 1
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outMode: "bounce",
                            random: false,
                            speed: 3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 900
                            },
                            value: 40
                        },
                        opacity: {
                            value: 0.4
                        },
                        shape: {
                            type: "circle"
                        },
                        size: {
                            random: true,
                            value: 5
                        }
                    }
                }}
            />
            <OverlayImage />
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <BannerContent>
                            <BannerHeader>
                                #Buy and sell Cryptocurrencies & Giftcard with
                                <Span> PAA!</Span>
                            </BannerHeader>
                            <BannerSubHeader>
                                A wide range of supported cryptocurrencies,
                                Clean and Elegant Design, Easy and Safe to use.
                            </BannerSubHeader>
                        </BannerContent>
                    </Grid>
                    <Grid item>
                        <AnimationWrapper>
                            <CircleAnimation>
                                <CircleAnimationArc1>
                                    <CircleAnimationDotL1D1 />
                                    <CircleAnimationDotL1D2 />
                                    <CircleAnimationDotL1D3 />
                                    <CircleAnimationDotL1D4 />
                                    <CircleAnimationDotL1D5 />
                                    <CircleAnimationDotL1D6 />
                                </CircleAnimationArc1>
                                <CircleAnimationArc2>
                                    <CircleAnimationDotL2D1 />
                                    <CircleAnimationDotL2D2 />
                                    <CircleAnimationDotL2D3 />
                                    <CircleAnimationDotL2D4 />
                                    <CircleAnimationDotL2D5 />
                                </CircleAnimationArc2>
                                <CircleAnimationArc3>
                                    <CircleAnimationDotL3D1 />
                                    <CircleAnimationDotL3D2 />
                                    <CircleAnimationDotL3D3 />
                                    <CircleAnimationDotL3D4 />
                                    <CircleAnimationDotL3D5 />
                                </CircleAnimationArc3>
                            </CircleAnimation>
                            <HeroButtonWrapper>
                                <PageLink href="/auth/login">
                                    <HeroButton variant="contained">
                                        {" "}
                                        Explore our Ecosystem
                                    </HeroButton>
                                </PageLink>
                            </HeroButtonWrapper>
                            <HeroIconWrapper>
                                <HeroIcon icon="cryptocurrency:btcp" />
                                <HeroIcon icon="cryptocurrency:etc" />
                                <HeroIcon icon="cryptocurrency:ltc" />
                                <HeroIcon icon="cryptocurrency:xrp" />
                            </HeroIconWrapper>
                        </AnimationWrapper>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Hero;

const RootStyle = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    overflow: "hidden",
    background: "#eff6ff",
    [theme.breakpoints.down("md")]: {
        height: "fit-content"
    }
}));
const ParticleStyle = styled(Particles)(() => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0
}));
const BannerContent = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: 120,
    maxWidth: 700,
    position: "relative",
    zIndex: 2
}));
const BannerHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h2,
    color: theme.palette.grey["800"],
    textAlign: "center",
    fontWeight: "500",
    paddingBottom: 20
}));
const BannerSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h5,
    color: theme.palette.grey["600"],
    fontWeight: "400",
    textAlign: "center",
    paddingBottom: 60
}));
const AnimationWrapper = styled(Box)(() => ({
    position: "relative"
}));
const CircleAnimation = styled(Box)(() => ({
    position: "absolute",
    height: 700,
    width: 700,
    top: 80,
    left: "50%",
    transform: "translateX(-50%)"
}));
const CircleAnimationWrapper = styled(Box)(() => ({
    position: "absolute",
    borderRadius: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid rgba(34, 59, 85, 0.15)"
}));
const CircleAnimationArc1 = styled(CircleAnimationWrapper)(() => ({
    height: "100%",
    width: "100%",
    animation: `${rotate} 78s linear infinite 0s`
}));
const CircleAnimationArc2 = styled(CircleAnimationWrapper)(() => ({
    height: "84.6%",
    width: "84.6%",
    animation: `${rotate} 78s linear infinite 0s`
}));
const CircleAnimationArc3 = styled(CircleAnimationWrapper)(() => ({
    height: "69.2%",
    width: "69.2%",
    animation: `${rotate} 78s linear infinite 0s`
}));
const CircleAnimationDotWrapper = styled(Box)(() => ({
    position: "absolute",
    height: 12,
    width: 12,
    borderRadius: "50%"
}));
const CircleAnimationDotL1D1 = styled(CircleAnimationDotWrapper)(() => ({
    left: "23%",
    top: "6.35%"
}));
const CircleAnimationDotL1D2 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: "23%",
    top: "6.35%",
    background: theme.palette.primary.light
}));
const CircleAnimationDotL1D3 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: -7,
    top: "50%",
    background: theme.palette.primary.dark
}));
const CircleAnimationDotL1D4 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: "23%",
    bottom: "6.35%",
    background: theme.palette.grey["400"]
}));
const CircleAnimationDotL1D5 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: "23%",
    bottom: "6.35%",
    background: theme.palette.primary.main
}));
const CircleAnimationDotL1D6 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: -7,
    top: "50%",
    background: theme.palette.grey["400"]
}));
const CircleAnimationDotL2D1 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: "4.9%",
    top: "25%",
    background: theme.palette.primary.main
}));
const CircleAnimationDotL2D2 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: "42%",
    top: "-.6%",
    background: theme.palette.primary.light
}));
const CircleAnimationDotL2D3 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: -2,
    top: "40%",
    background: theme.palette.grey["400"]
}));
const CircleAnimationDotL2D4 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: "24%",
    bottom: "5.5%",
    background: theme.palette.primary.dark
}));
const CircleAnimationDotL2D5 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: "11.2%",
    bottom: "16%",
    background: theme.palette.grey["400"]
}));
const CircleAnimationDotL3D1 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: "7.6%",
    top: "20%",
    background: theme.palette.primary.main
}));
const CircleAnimationDotL3D2 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: "24%",
    top: "5.2%",
    background: theme.palette.primary.light
}));
const CircleAnimationDotL3D3 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: -3,
    bottom: "39%",
    background: theme.palette.grey["400"]
}));
const CircleAnimationDotL3D4 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    right: "37%",
    bottom: 0,
    background: theme.palette.primary.dark
}));
const CircleAnimationDotL3D5 = styled(CircleAnimationDotWrapper)(({theme}) => ({
    left: "8.4%",
    bottom: "19%",
    background: theme.palette.grey["400"]
}));
const HeroButtonWrapper = styled(Box)(() => ({
    position: "relative",
    paddingTop: 250
}));
const HeroButton = styled(Button)(() => ({
    display: "flex",
    justifyContent: "center",
    padding: "8px 22px",
    height: 48,
    width: "100%"
}));
const HeroIconWrapper = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: "50px 0"
}));
const HeroIcon = styled(Icon)(({theme}) => ({
    fontSize: 25,
    color: theme.palette.grey["600"],
    marginRight: 10,
    "&:hover": {
        color: theme.palette.primary.main,
        cursor: "pointer"
    }
}));
const rotate = keyframes`
    from {
        transform: translate(-50%, -50%) rotate(
        0deg)
    }
    to {
        transform: translate(-50%, -50%) rotate(
            360deg)
    }
    `;
const Span = styled("span")(({theme}) => ({
    color: theme.palette.primary.main
}));
