import React, {useState} from "react";
import {Box, Button, Divider, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import {animateScroll as scroll, Link as LinkS} from "react-scroll";
import {ContentBody, PageLink} from "../component";
import {MotionInView, varFadeInDown, varFadeInUp} from "../animate";

const Footer = () => {
    const [email, setEmail] = useState("");
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterStyle>
            <MotionInView variants={varFadeInDown}>
                <MessageBox>
                    <BoxWrapper>
                        <BoxContent>
                            <BoxHeader sx={{color: "#fff"}}>
                                Do not miss out, Stay updated
                            </BoxHeader>
                            <BoxSubHeader sx={{color: "#fff"}}>
                                Sign up for updates and market news. Subscribe
                                to our newsletter and receive update about PAA
                                and crypto tips
                            </BoxSubHeader>
                        </BoxContent>
                        <BoxInputWrapper
                            action="https://one.us8.list-manage.com/subscribe/post?u=c292825b21d947022fc40abe4&amp;id=267d099b3d&amp;f_id=002878e0f0"
                            method="post"
                            id="mc-embedded-subscribe-form"
                            target="_blank"
                            noValidate>
                            <BoxInput
                                placeholder="Enter your email"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                name="EMAIL"
                                id="mce-EMAIL"
                            />
                            <BoxInputButton
                                variant="contained"
                                type="submit"
                                value="Subscribe"
                                name="subscribe"
                                id="mc-embedded-subscribe">
                                Subscribe
                            </BoxInputButton>
                        </BoxInputWrapper>
                    </BoxWrapper>
                </MessageBox>
            </MotionInView>
            <FooterDivider />
            <MotionInView variants={varFadeInUp}>
                <IconWrapper>
                    <FooterLink href="https://www.facebook.com/profile.php?id=100087843703462">
                        <FooterIcon icon="bx:bxl-facebook" />
                    </FooterLink>
                    <FooterLink href="https://www.instagram.com/paa.crypto/">
                        <FooterIcon icon="ant-design:instagram-outlined" />
                    </FooterLink>
                    <FooterLink href="https://twitter.com/paa_crypto">
                        <FooterIcon icon="ant-design:twitter-outlined" />
                    </FooterLink>
                </IconWrapper>
            </MotionInView>
            <MotionInView variants={varFadeInUp}>
                <LogoWrapper>
                    <LogoLink to="/" onClick={toggleHome}>
                        PAA
                    </LogoLink>
                    <ContentBody>
                        PAA &#169; {new Date().getFullYear()}. All rights
                        reserved.
                    </ContentBody>
                </LogoWrapper>
            </MotionInView>
        </FooterStyle>
    );
};

export default Footer;

const FooterStyle = styled(Box)(() => ({
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    overflow: "hidden",
    padding: "80px 0 40px"
}));
const MessageBox = styled(Box)(({theme}) => ({
    maxWidth: 1100,
    position: "relative",
    height: "fit-content",
    zIndex: 2,
    margin: "auto",
    padding: 60,
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    borderRadius: theme.shape.borderRadiusMd,
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("lg")]: {
        maxWidth: 870,
        padding: "60px 30px"
    },
    [theme.breakpoints.down("md")]: {
        maxWidth: 585
    },
    [theme.breakpoints.down("sm")]: {
        maxWidth: 500
    }
}));
const BoxWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column"
    }
}));
const BoxContent = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: 520,
    [theme.breakpoints.down("lg")]: {
        maxWidth: 400
    },
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        paddingBottom: 20
    }
}));
const BoxHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1
}));
const BoxSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.body1
}));
const BoxInputWrapper = styled("form")(({theme}) => ({
    marginLeft: 80,
    maxWidth: 480,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
        marginLeft: 20,
        maxWidth: 500
    },
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        margin: "auto"
    }
}));
const BoxInput = styled("input")(({theme}) => ({
    outline: "none",
    padding: "0 25px",
    paddingRight: "40%",
    border: "none",
    borderRadius: 5,
    height: 65,
    width: "100%",
    background: theme.palette.background.default,
    transition: ".3s",
    fontSize: 16,
    position: "relative",
    color: theme.palette.text.primary,
    "&::placeholder": {
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily
    },
    [theme.breakpoints.down("lg")]: {
        paddingRight: "50%"
    },
    [theme.breakpoints.down("md")]: {
        height: 55,
        paddingRight: 25
    }
}));
const BoxInputButton = styled(Button)(({theme}) => ({
    position: "absolute",
    padding: "12px 35px",
    right: 70,
    top: "38.5%",
    color: "#fff",
    background: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
        right: 40
    },
    "&:hover": {
        background: theme.palette.primary.dark,
        color: "#fff",
        boxShadow: theme.customShadows.primary
    },
    [theme.breakpoints.down("md")]: {
        position: "relative",
        width: "100%",
        right: 0,
        marginTop: 15,
        boxShadow: theme.shadows[2]
    }
}));
const FooterDivider = styled(Divider)(({theme}) => ({
    marginTop: -110,
    [theme.breakpoints.down("md")]: {
        marginTop: -155
    }
}));
const FooterLink = styled(PageLink)(({theme}) => ({
    "&:not(:first-of-type)": {
        marginLeft: 10
    }
}));
const IconWrapper = styled(Box)(() => ({
    paddingTop: 200,
    display: "flex",
    justifyContent: "center"
}));
const FooterIcon = styled(Icon)(({theme}) => ({
    fontSize: 40,
    background: theme.palette.primary.light,
    borderRadius: "50%",
    cursor: "pointer",
    padding: 10,
    color: "#fff",
    "&:hover": {
        background: theme.palette.background.paper,
        color: theme.palette.primary.light,
        transform: "scale(1.05)",
        transition: "0.3s ease",
        boxShadow: theme.customShadows.primary
    }
}));
const LogoWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 30
}));
const LogoLink = styled(LinkS)(({theme}) => ({
    ...theme.typography.h4,
    color: theme.palette.primary.main,
    paddingBottom: 20,
    cursor: "pointer"
}));
