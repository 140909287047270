export const faqItems = [
    {
        id: 1,
        question: "Can i buy giftcard with crypto?",
        answer: "Yes, you can buy giftcard with crypto on our system",
        panel: "panel1"
    },
    {
        id: 2,
        question: "Does it support multiple languages?",
        answer: "Yes we do, you can however get in contact with our support if your desired locale isn't available in the system!",
        panel: "panel2"
    },
    {
        id: 3,
        question: "Are all cryptocurrencies supported?",
        answer: "No, not all currencies are supported, more would be made available over time!",
        panel: "panel3"
    },
    {
        id: 4,
        question: "How can i change my preferred language?",
        answer: "You'll find a selection panel to change to available locales on the home page!",
        panel: "panel4"
    },
    {
        id: 5,
        question: "I want my data deleted?",
        answer: "Please contact our support using the Get support button in the system or through the contact form below!",
        panel: "panel5"
    },
    {
        id: 6,
        question: "My region isn't supported",
        answer: "We are limited to the countries we can support, however you can get in contact with our support for a feedback on if your region can be supported or not",
        panel: "panel5"
    }
];

export const depositItems = [
    {
        id: 1,
        question: "How do I deposit into my account?",
        answer: "You can make use of direct bank transfer if available for your region or the payment gateway available in the system.",
        panel: "panel1"
    },
    {
        id: 2,
        question: "I made multiple deposit but its showing only once",
        answer: "If changes do not reflect after a while, please contact our support for assistance",
        panel: "panel2"
    },
    {
        id: 3,
        question: "Deposit not showing in my wallet?",
        answer: "If changes do not reflect after a while, please contact our support for assistance",
        panel: "panel3"
    },
    {
        id: 4,
        question: "I didn't use my own bank account",
        answer: "You can change your desired bank account at any time!",
        panel: "panel4"
    },
    {
        id: 5,
        question: "I need help with payment methods",
        answer: "For assistance please make use of the Get support button in the system or through the contact form below! ",
        panel: "panel5"
    }
];

export const accountItems = [
    {
        id: 1,
        question: "How do I set up my account?",
        answer: "You get started with by registering an account with us and completing your kyc verification",
        panel: "panel1"
    },
    {
        id: 2,
        question: "How do I close down my account?",
        answer: "Contact our support as regards getting your account deleted",
        panel: "panel2"
    },
    {
        id: 3,
        question: "My account is locked",
        answer: "If your account is disabled, we have noticed strange activities on your account. Contact our support on how to resolve this issue",
        panel: "panel3"
    },
    {
        id: 4,
        question: "How can I change verified personal details?",
        answer: "Contact out support to change verified documents",
        panel: "panel4"
    },
    {
        id: 5,
        question: "How can i change my preferred language?",
        answer: "You'll find a selection panel to change to available locales on the home page!",
        panel: "panel5"
    }
];

export const securityItems = [
    {
        id: 1,
        question: "Is PAA regulated ?",
        answer: "Yes, PAA is regulated!",
        panel: "panel1"
    },
    {
        id: 2,
        question: "How secure is my transactions?",
        answer: "We have taken a lot of security precautions to ensure that our system is protected from all sorts of security threats from within and outside the system",
        panel: "panel2"
    },
    {
        id: 3,
        question: "How can I reset my account password?",
        answer: "You can reset your password in the security tab under Account settings!",
        panel: "panel3"
    },
    {
        id: 4,
        question: "How can I report a fraudulent transaction?",
        answer: "Contact our support using the Get support button in the system or the contact form below to report a fraudulent activity",
        panel: "panel4"
    }
];
