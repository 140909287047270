import React from "react";
import {styled} from "@mui/material/styles";
import {Box, Container, Grid, Typography} from "@mui/material";
import {MotionInView, varFadeInUp} from "../animate";
import {ContentTitle, ImageAnimation, Line, RootStyle} from "../component";
import aboutImg from "../../../assets/img/about.png";
import aboutDarkImg from "../../../assets/img/aboutDark.png";
import useSettings from "../../../hooks/useSettings";

const About = () => {
    const {themeMode} = useSettings();
    const isLight = themeMode === "dark";

    return (
        <RootStyle id="about" sx={{padding: 0}}>
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <ImageWrapper>
                            <ImageAnimation />
                            {isLight ? (
                                <Image src={aboutImg} />
                            ) : (
                                <Image src={aboutDarkImg} />
                            )}
                        </ImageWrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MotionInView variants={varFadeInUp}>
                            <AboutWrapper>
                                <AboutTitleWrapper>
                                    <Line />
                                    <ContentTitle>
                                        What is Cryptitan
                                    </ContentTitle>
                                </AboutTitleWrapper>
                                <AboutHeader>
                                    We’ve built a platform to buy and sell
                                    digital assets
                                </AboutHeader>
                                <AboutSubHeader>
                                    PAA is a multi-featured Cryptocurrency
                                    exchange software.
                                </AboutSubHeader>
                                <AboutBody>
                                    With support for multiple Cryptocurrencies,
                                    you are able to host buy & sell with direct
                                    bank transfer, credit cards or any other
                                    supported payment gateways.
                                </AboutBody>
                            </AboutWrapper>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default About;
const AboutWrapper = styled(Box)(({theme}) => ({
    maxWidth: 450,
    padding: "200px 0",
    [theme.breakpoints.down("md")]: {
        margin: "auto",
        padding: "100px 0"
    }
}));
const AboutTitleWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        justifyContent: "center"
    }
}));
const AboutHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.h3,
    fontWeight: 500,
    lineHeight: 1.1,
    padding: "20px 0",
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));
const AboutSubHeader = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.grey["600"],
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));
const AboutBody = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: theme.palette.grey["500"],
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));
const ImageWrapper = styled(Box)(({theme}) => ({
    maxWidth: 600,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        margin: "0px 0 40px"
    },
    [theme.breakpoints.down("sm")]: {
        margin: "-70px 0px 0px"
    }
}));
const Image = styled("img")(({theme}) => ({
    width: "90%",
    boxShadow: theme.customShadows.primary,
    marginLeft: "-20%",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        width: "80%",
        marginTop: 100
    },
    [theme.breakpoints.down("sm")]: {
        width: "80%",
        marginTop: 140
    }
}));
