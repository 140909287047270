import React from "react";
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Button,
    Box,
    RadioGroup,
    CardActionArea,
    FormControlLabel,
    Radio
} from "@mui/material";
import {navItems} from "../header/data";
import {Link as ScrollLink} from "react-scroll";
import {Close} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {PageLink} from "../component";
import {Icon} from "@iconify/react";
import sunFill from "@iconify-icons/eva/sun-fill";
import moonFill from "@iconify-icons/eva/moon-fill";
import useSettings from "../../../hooks/useSettings";

const Sidebar = ({open, toggle}) => {
    const drawerWidth = 400;
    const {themeMode, onChangeMode} = useSettings();

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={toggle}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                }
            }}>
            <List>
                <SideBarListItem onClick={toggle}>
                    <ListItemIcon sx={{minWidth: "auto"}}>
                        <CloseIcon />
                    </ListItemIcon>
                </SideBarListItem>
                <Box>
                    {navItems.map((sideItem) => (
                        <ListItem
                            key={sideItem.id}
                            sx={{flexDirection: "column"}}>
                            <ListItemLink
                                to={sideItem.idm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                data-name={sideItem.name}
                                onClick={toggle}>
                                {sideItem.name}
                            </ListItemLink>
                        </ListItem>
                    ))}
                </Box>
            </List>
            <SideBarButtonWrapper>
                <PageLink href="/auth/register">
                    <SideBarButton variant="outlined">
                        Get Started
                    </SideBarButton>
                </PageLink>
            </SideBarButtonWrapper>
            <ThemeContainer>
                <RadioGroup
                    name="themeMode"
                    onChange={onChangeMode}
                    value={themeMode}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        {["dark", "light"].map((mode, index) => (
                            <CardActionArea
                                sx={{color: "primary.main"}}
                                key={mode}>
                                <Box
                                    sx={{
                                        py: 4,
                                        display: "flex",
                                        color: "text.disabled",
                                        justifyContent: "center",
                                        ...(themeMode === mode && {
                                            color: "primary.main"
                                        })
                                    }}>
                                    <Icon
                                        icon={index === 0 ? sunFill : moonFill}
                                        width={24}
                                        height={24}
                                    />
                                </Box>

                                <FormControlLabel
                                    label=""
                                    value={mode}
                                    control={<Radio sx={{display: "none"}} />}
                                    sx={{
                                        top: 0,
                                        margin: 0,
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute"
                                    }}
                                />
                            </CardActionArea>
                        ))}
                    </Box>
                </RadioGroup>
            </ThemeContainer>
        </Drawer>
    );
};

export default Sidebar;
const SideBarListItem = styled(ListItem)(() => ({
    justifyContent: "flex-end",
    cursor: "pointer"
}));
const CloseIcon = styled(Close)(({theme}) => ({
    fontSize: "30px",
    color: theme.palette.primary.main
}));
const ListItemLink = styled(ScrollLink)(({theme}) => ({
    position: "relative",
    padding: "25px 0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&::before": {
        content: "attr(data-name)",
        position: "absolute",
        width: "0px",
        transition: "all .5s ease-in",
        overflow: "hidden",
        color: theme.palette.primary.light
    },
    "&:hover::before": {
        width: "100%",
        transition: "all .5s ease-out"
    }
}));
const SideBarButtonWrapper = styled(Box)(() => ({
    display: "flex",
    padding: "50px 0",
    justifyContent: "center"
}));
const SideBarButton = styled(Button)(({theme}) => ({
    width: "170px",
    height: 42,
    fontWeight: "bold",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
    "&:hover": {
        background: theme.palette.primary.main,
        color: "#fff",
        transition: ".3s all ease",
        boxShadow: theme.customShadows.primary
    }
}));
const ThemeContainer = styled(Box)(({theme}) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center"
}));
