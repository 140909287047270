import React, {useState, useEffect} from "react";
import {
    About,
    Contact,
    CryptoTable,
    Explore,
    Faq,
    Features,
    Footer,
    Header,
    Hero,
    Info,
    Sidebar
} from "./components";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import getCryptoData from "../axios";

const Landing = () => {
    const theme = useTheme();
    const url =
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&sparkline=false";
    const [isOpen, setIsOpen] = useState(false);
    const [cryptoData, setCryptoData] = useState([]);
    const [scrollNav, setScrollNav] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", changeNav);
        getCryptoData(url).then((newData) => {
            setCryptoData(newData);
        });
    }, []);
    const changeNav = () => {
        if (window.scrollY >= 100) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    });
    const open = isMd ? false : isOpen;
    return (
        <React.Fragment>
            <Header scrollNav={scrollNav} toggle={toggle} isopen={isOpen} />
            <Sidebar open={open} toggle={toggle} />
            <Hero />
            <CryptoTable cryptoData={cryptoData} />
            <About />
            <Features />
            <Info />
            <Explore />
            <Faq />
            <Contact />
            <Footer />
        </React.Fragment>
    );
};

export default Landing;
