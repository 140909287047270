import React from "react";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Grid,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {
    ContentHeader,
    ContentSubHeader,
    ContentTitle,
    ContentTitleWrapper,
    ContentWrapper,
    Line,
    RootStyle
} from "../component";
import {accountItems, depositItems, faqItems, securityItems} from "./data";
import {ExpandLess} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInLeft, varFadeInUp} from "../animate";

const Faq = () => {
    const [value, setValue] = React.useState(0);

    const handlerChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box sx={{p: 3}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    }

    const [expanded, setExpanded] = React.useState("panel1");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <RootStyle id="faqs">
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item container direction="column" xs={12} md={6}>
                        <Grid item>
                            <MotionInView variants={varFadeInLeft}>
                                <ContentWrapper sx={{paddingBottom: 0}}>
                                    <ContentTitleWrapper>
                                        <Line />
                                        <ContentTitle>FAQs</ContentTitle>
                                    </ContentTitleWrapper>
                                    <ContentHeader>
                                        Frequently Asked Questions
                                    </ContentHeader>
                                    <ContentSubHeader>
                                        Below we’ve provided a bit of General
                                        Issues, Deposit related, Account
                                        related, and Security Related. If you
                                        have any other questions, please get in
                                        touch using the contact form below.
                                    </ContentSubHeader>
                                </ContentWrapper>
                            </MotionInView>
                        </Grid>
                        <Grid item>
                            <MotionInView variants={varFadeInLeft}>
                                <Tabs
                                    value={value}
                                    indicatorColor="none"
                                    onChange={handlerChange}
                                    aria-label="faq tabs">
                                    <FaqTab label="General" {...a11yProps(0)} />
                                    <FaqTab
                                        label="Transactions"
                                        {...a11yProps(1)}
                                    />
                                    <FaqTab
                                        label="Accounts"
                                        {...a11yProps(2)}
                                    />
                                    <FaqTab
                                        label="Security"
                                        {...a11yProps(3)}
                                    />
                                </Tabs>
                            </MotionInView>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MotionInView variants={varFadeInUp}>
                            <TabPanel value={value} index={0}>
                                {faqItems.map((generalItem) => (
                                    <FaqAccordion
                                        expanded={
                                            expanded === generalItem.panel
                                        }
                                        onChange={handleChange(
                                            generalItem.panel
                                        )}
                                        key={generalItem.id}>
                                        <FaqAccordionSummary
                                            aria-controls={
                                                generalItem.togglerId +
                                                "d-content"
                                            }
                                            id={
                                                generalItem.togglerId +
                                                "d-header"
                                            }
                                            expandIcon={<FaqExpandLess />}>
                                            {generalItem.question}
                                        </FaqAccordionSummary>
                                        <FaqAccordionDetails>
                                            {generalItem.answer}
                                        </FaqAccordionDetails>
                                    </FaqAccordion>
                                ))}
                            </TabPanel>
                        </MotionInView>
                        <TabPanel value={value} index={1}>
                            {depositItems.map((depositItem) => (
                                <FaqAccordion
                                    expanded={expanded === depositItem.panel}
                                    onChange={handleChange(depositItem.panel)}
                                    key={depositItem.id}>
                                    <FaqAccordionSummary
                                        aria-controls={
                                            depositItem.togglerId + "d-content"
                                        }
                                        id={depositItem.togglerId + "d-header"}
                                        expandIcon={<FaqExpandLess />}>
                                        {depositItem.question}
                                    </FaqAccordionSummary>
                                    <FaqAccordionDetails>
                                        {depositItem.answer}
                                    </FaqAccordionDetails>
                                </FaqAccordion>
                            ))}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {accountItems.map((accountItem) => (
                                <FaqAccordion
                                    expanded={expanded === accountItem.panel}
                                    onChange={handleChange(accountItem.panel)}
                                    key={accountItem.id}>
                                    <FaqAccordionSummary
                                        aria-controls={
                                            accountItem.togglerId + "d-content"
                                        }
                                        id={accountItem.togglerId + "d-header"}
                                        expandIcon={<FaqExpandLess />}>
                                        {accountItem.question}
                                    </FaqAccordionSummary>
                                    <FaqAccordionDetails>
                                        {accountItem.answer}
                                    </FaqAccordionDetails>
                                </FaqAccordion>
                            ))}
                        </TabPanel>
                        <TabPanel index={3} value={value}>
                            {securityItems.map((securityItem) => (
                                <FaqAccordion
                                    expanded={expanded === securityItem.panel}
                                    onChange={handleChange(securityItem.panel)}
                                    key={securityItem.id}>
                                    <FaqAccordionSummary
                                        aria-controls={
                                            securityItem.togglerId + "d-content"
                                        }
                                        id={securityItem.togglerId + "d-header"}
                                        expandIcon={<FaqExpandLess />}>
                                        {securityItem.question}
                                    </FaqAccordionSummary>
                                    <FaqAccordionDetails>
                                        {securityItem.answer}
                                    </FaqAccordionDetails>
                                </FaqAccordion>
                            ))}
                        </TabPanel>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};
export default Faq;

const FaqTab = styled(Tab)(({theme}) => ({
    background: theme.palette.primary.lighter,
    padding: "3px 10px",
    fontWeight: "lighter",
    borderRadius: 5,
    "&:hover": {
        color: theme.palette.primary.main
    },
    "&.Mui-selected": {
        color: theme.palette.primary.main
    }
}));
const FaqAccordion = styled(Accordion)(({theme}) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: 8,
    marginBottom: 10,
    boxShadow: theme.customShadows.primary,
    "&.Mui-expanded": {
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: theme.customShadows.primary
    },
    [theme.breakpoints.down("md")]: {
        "&:first-of-type": {
            marginTop: 40
        }
    }
}));
const FaqAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    ...theme.typography.subtitle1,
    fontWeight: 400,
    color: theme.palette.text.primary
}));
const FaqAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    ...theme.typography.body2,
    color: theme.palette.grey[500]
}));
const FaqExpandLess = styled(ExpandLess)(({theme}) => ({
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
    borderRadius: "50%",
    padding: 5,
    fontSize: 30
}));
