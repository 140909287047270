import * as React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Paper,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Typography,
    Container,
    TableFooter,
    TablePagination,
    Box,
    IconButton,
    tableCellClasses
} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import {RootStyle} from "../component";
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    FirstPage,
    LastPage
} from "@mui/icons-material";

function TablePaginationActions(props) {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page">
                {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

const CryptoTable = ({cryptoData}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - cryptoData.length)
            : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <RootStyle>
            <Container maxWidth="lg">
                <TableContainer
                    component={Paper}
                    sx={{boxShadow: (theme) => theme.customShadows.primary}}>
                    <StyledTable sx={{minWidth: 650}} aria-label="simple table">
                        <StyledTableHead>
                            <StyledTableRow>
                                <StyledTableCell align="left">
                                    #
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    Name
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    Price($)
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    Change
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Trade
                                </StyledTableCell>
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? cryptoData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : cryptoData
                            ).map((crypto, index) => {
                                const str =
                                    crypto.price_change_percentage_24h.toString();
                                return (
                                    <StyledTableRow
                                        key={crypto.id}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0
                                                }
                                        }}>
                                        <IndexCell>{index + 1}</IndexCell>
                                        <NameCell>
                                            <CryptoNameWrapper>
                                                <CryptoImage
                                                    src={crypto.image}
                                                    alt="crypto_img"
                                                />{" "}
                                                {crypto.name}
                                                <CryptoAcr>
                                                    {crypto.symbol.toUpperCase()}
                                                </CryptoAcr>
                                            </CryptoNameWrapper>
                                        </NameCell>
                                        <PriceCell align="left">
                                            {"$" + crypto.current_price}
                                        </PriceCell>
                                        <ChangeCell align="left">
                                            {str.includes("-") ? (
                                                <PriceRed>
                                                    {Math.round(
                                                        (crypto.price_change_percentage_24h +
                                                            Number.EPSILON) *
                                                            100
                                                    ) /
                                                        100 +
                                                        "%"}
                                                </PriceRed>
                                            ) : (
                                                <PriceGreen>
                                                    {Math.round(
                                                        (crypto.price_change_percentage_24h +
                                                            Number.EPSILON) *
                                                            100
                                                    ) /
                                                        100 +
                                                        "%"}
                                                </PriceGreen>
                                            )}
                                        </ChangeCell>
                                        <ButtonCell align="center">
                                            <Button
                                                variant="contained"
                                                href="/auth/login"
                                                sx={{
                                                    padding: "8px 22px",
                                                    height: 48
                                                }}>
                                                Trade
                                            </Button>
                                        </ButtonCell>
                                    </StyledTableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 53 * emptyRows}}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <StyledTableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        4,
                                        8,
                                        11,
                                        {label: "All", value: -1}
                                    ]}
                                    colSpan={3}
                                    count={cryptoData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </StyledTableFooter>
                    </StyledTable>
                </TableContainer>
            </Container>
        </RootStyle>
    );
};
export default CryptoTable;

const PriceRed = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: "red",
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const PriceGreen = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    color: "#098551",
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const CryptoNameWrapper = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));
const CryptoImage = styled("img")(() => ({
    width: 30,
    marginRight: 10
}));
const CryptoAcr = styled(Typography)(({theme}) => ({
    ...theme.typography.body1,
    marginLeft: 10,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
        fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
}));
const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));
const StyledTableHead = styled(TableHead)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));
const StyledTable = styled(Table)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        overflow: "hidden"
    }
}));
const StyledTableFooter = styled(TableFooter)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        overflow: "hidden"
    }
}));

const IndexCell = styled(StyledTableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));
const NameCell = styled(StyledTableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        width: "70%"
    }
}));
const PriceCell = styled(StyledTableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        width: "30%"
    }
}));
const ChangeCell = styled(StyledTableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));
const ButtonCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));
