import React from "react";
import {
    Box,
    Grid,
    Button,
    TextareaAutosize,
    TextField,
    Typography,
    Container,
    Alert,
    Modal,
    CircularProgress
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
    ContentHeader,
    ContentSubHeader,
    ContentTitle,
    ContentTitleWrapper,
    ContentWrapper,
    IconBackground,
    Line,
    RootStyle
} from "../component";
import {Icon} from "@iconify/react";
import {MotionInView, varFadeInLeft, varFadeInUp} from "../animate";
import emailjs from "@emailjs/browser";

const Contact = () => {
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [toSend, setToSend] = React.useState({
        from_name: "",
        to_name: "",
        message: "",
        reply_to: ""
    });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSuccess(false);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        emailjs
            .send(
                "service_q9r4epw",
                "template_05s71oj",
                toSend,
                "0TPxjiYv-U7j4RWjE"
            )
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    setIsSuccess(true);
                    setIsLoading(false);
                } else {
                    setIsError(true);
                }
            })
            .catch(() => {
                setIsError(true);
            });
    };

    const handleChange = (e) => {
        setToSend({...toSend, [e.target.name]: e.target.value});
    };
    return (
        <RootStyle id="contact" sx={{background: "#eff6ff"}}>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={5} container direction="column">
                        <Grid item>
                            <MotionInView variants={varFadeInLeft}>
                                <ContentWrapper>
                                    <ContentTitleWrapper>
                                        <Line />
                                        <ContentTitle>Contact Us</ContentTitle>
                                    </ContentTitleWrapper>
                                    <ContentHeader sx={{color: "#000"}}>
                                        Get In Touch
                                    </ContentHeader>
                                    <ContentSubHeader>
                                        Any question? Reach out to us and we
                                        will get back to you shortly.
                                    </ContentSubHeader>
                                </ContentWrapper>
                            </MotionInView>
                        </Grid>
                        <Grid item>
                            <MotionInView variants={varFadeInLeft}>
                                <ContactIconWrapper>
                                    {contactItems.map((contactItem) => (
                                        <ContactContent
                                            key={contactItem.id}
                                            onClick={() => {
                                                window.open(
                                                    contactItem.webUrl,
                                                    "_blank"
                                                );
                                            }}>
                                            <ContactIconBackground>
                                                <ContactIcon
                                                    icon={contactItem.icon}
                                                />
                                            </ContactIconBackground>
                                            <ContactText>
                                                {contactItem.text}
                                            </ContactText>
                                        </ContactContent>
                                    ))}
                                </ContactIconWrapper>
                            </MotionInView>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <MotionInView variants={varFadeInUp}>
                            <ContactForm onSubmit={onSubmit}>
                                <CustomtextField
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    name="from_name"
                                    value={toSend.from_name}
                                    onChange={handleChange}
                                    required
                                />
                                <CustomtextField
                                    id="outlined"
                                    label="Email"
                                    variant="outlined"
                                    required
                                    type="Email"
                                    name="reply_to"
                                    value={toSend.reply_to}
                                    onChange={handleChange}
                                />
                                <ContactTextareaAutosize
                                    aria-label="get-in-touch"
                                    minRows={10}
                                    name="message"
                                    placeholder="Your message"
                                    value={toSend.message}
                                    onChange={handleChange}
                                />
                                <ButtonWrapper>
                                    <ContactButton
                                        type="submit"
                                        variant="contained">
                                        Send Message{" "}
                                        {isLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    marginLeft: "10px",
                                                    color: "#fff"
                                                }}
                                            />
                                        ) : (
                                            <ButtonIcon icon="fluent:send-20-regular" />
                                        )}
                                    </ContactButton>
                                </ButtonWrapper>
                            </ContactForm>
                            <Modal
                                open={isSuccess}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <ModalStyle>
                                    <Alert
                                        severity={isError ? "error" : "success"}
                                        sx={{
                                            height: "150px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                        {isError
                                            ? "Message failed to deliver"
                                            : "Message Sent Successfully"}
                                    </Alert>
                                </ModalStyle>
                            </Modal>
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default Contact;
const ContactIconWrapper = styled(Box)(({theme}) => ({
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
        marginTop: 30
    }
}));
const ContactContent = styled(Box)(() => ({
    display: "flex",
    marginBottom: 40,
    alignItems: "center"
}));
const ContactIconBackground = styled(IconBackground)(({theme}) => ({
    height: 50,
    width: 50,
    cursor: "pointer",
    background: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
        marginBottom: 10
    },
    [theme.breakpoints.down("sm")]: {
        height: 40,
        width: 40
    }
}));
const ContactIcon = styled(Icon)(({theme}) => ({
    color: "#fff",
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
        fontSize: 24
    }
}));
const ContactText = styled(Typography)(({theme}) => ({
    ...theme.typography.body2,
    paddingLeft: 25,
    color: theme.palette.grey[600]
}));
const ContactForm = styled("form")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    paddingLeft: 50,
    [theme.breakpoints.down("md")]: {
        padding: 0
    }
}));
const ModalStyle = styled(Box)(({theme}) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: theme.shape.borderRadiusMd,
    border: "2px solid transparent",
    boxShadow: 24,
    p: 4
}));
const CustomtextField = styled(TextField)(({theme}) => ({
    marginBottom: 20,
    boxShadow: theme.customShadows.primary,
    "& input:valid + fieldset": {
        border: `1px solid ${theme.palette.primary.main}`
    },
    "& input:invalid + fieldset": {
        border: `1px solid ${theme.palette.primary.main}`
    },
    "& input:valid:focus + fieldset": {
        borderLeftWidth: 6,
        padding: "4px !important" // override inline-style
    },
    "& label": {
        color: theme.palette.primary.light,
        fontSize: 14
    },
    "& input": {
        color: theme.palette.primary.light,
        fontSize: 14
    }
}));
const ContactTextareaAutosize = styled(TextareaAutosize)(({theme}) => ({
    padding: 15,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.light,
    fontSize: 14,
    border: `1px solid ${theme.palette.primary.main}`,
    marginBottom: 40,
    boxShadow: theme.customShadows.primary,
    borderRadius: 7,
    background: "transparent",
    zIndex: 2,
    "&:hover": {
        outline: "none"
    },
    "&::placeholder": {
        color: theme.palette.primary.light
    },
    "&:focus": {
        outline: "none"
    }
}));
const ButtonWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center"
}));
const ContactButton = styled(Button)(() => ({
    position: "relative",
    height: 48,
    letterSpacing: 0.7,
    padding: "8px 22px",
    border: "none",
    cursor: "pointer",
    color: "#fff"
}));
const ButtonIcon = styled(Icon)(() => ({
    fontSize: 40,
    paddingLeft: 10
}));

const contactItems = [
    {
        id: 1,
        text: "+44 7500725687",
        icon: "bxs:phone-call",
        webUrl: "tel:+447500725687"
    },
    {
        id: 3,
        text: "Join us on telegram",
        icon: "cib:telegram-plane",
        webUrl: "https://t.me/+h3K0TMwD4Hw2ZTBk"
    }
];
