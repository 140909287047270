import React from "react";
import {Box, Container, Divider, Grid, Stack} from "@mui/material";
import {
    ContentBody,
    ContentHeader,
    ContentSubHeader,
    ContentTitle,
    ContentTitleWrapper,
    ContentWrapper,
    Line,
    RootStyle
} from "../component";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInLeft, varFadeInUp} from "../animate";
import WalletSvg from "./component/wallet";
import ExchangeSvg from "./component/exchange";
import GiftcardSvg from "./component/GiftcardSvg";

const Explore = () => {
    return (
        <RootStyle sx={{background: "#eff6ff"}}>
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item>
                        <MotionInView variants={varFadeInLeft}>
                            <ContentWrapper sx={{paddingBottom: "40px"}}>
                                <ContentTitleWrapper>
                                    <Line />
                                    <ContentTitle>PAA FEATURE</ContentTitle>
                                </ContentTitleWrapper>
                                <ContentHeader sx={{color: "#000"}}>
                                    Ecosystem key features
                                </ContentHeader>
                                <ContentSubHeader>
                                    Listed Below are the key features of our
                                    ecosystem. Our development team is
                                    constantly working to make more amazing
                                    features available to our users.
                                </ContentSubHeader>
                            </ContentWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid item>
                        <Stack
                            direction={{xs: "column", md: "row"}}
                            spacing={{xs: 0, md: 5}}
                            divider={
                                <Divider orientation="vertical" flexItem />
                            }>
                            <MotionInView variants={varFadeInUp}>
                                <ItemWrapper>
                                    <IconWrapper>
                                        <WalletSvg />
                                    </IconWrapper>
                                    <ContentSubHeader
                                        sx={{textAlign: "center"}}>
                                        Self Hosted Wallets
                                    </ContentSubHeader>
                                    <ExploreBody>
                                        Using custodial wallet providers, PAA
                                        provides wallet functionalities to
                                        registered users with unique receiving
                                        addresses
                                    </ExploreBody>
                                </ItemWrapper>
                            </MotionInView>
                            <MotionInView variants={varFadeInUp}>
                                <ItemWrapper>
                                    <IconWrapper>
                                        <ExchangeSvg />
                                    </IconWrapper>
                                    <ContentSubHeader
                                        sx={{textAlign: "center"}}>
                                        Instant Trader and Exchange
                                    </ContentSubHeader>
                                    <ExploreBody>
                                        PAA supports buying and selling of
                                        Cryptocurrencies with direct bank
                                        transfer or payment gateways, with
                                        optional setting of exchange fees.
                                    </ExploreBody>
                                </ItemWrapper>
                            </MotionInView>
                            <MotionInView variants={varFadeInUp}>
                                <ItemWrapper>
                                    <IconWrapper>
                                        <GiftcardSvg />
                                    </IconWrapper>
                                    <ContentSubHeader
                                        sx={{textAlign: "center"}}>
                                        Giftcard Exchange
                                    </ContentSubHeader>
                                    <ExploreBody>
                                        PAA supports giftcard marketplace
                                        listing, with checkout and payment
                                        fulfilment using the existing payment
                                        account of users.
                                    </ExploreBody>
                                </ItemWrapper>
                            </MotionInView>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};
export default Explore;

export const ItemWrapper = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: 320,
    [theme.breakpoints.down("lg")]: {
        maxWidth: 250,
        margin: "auto"
    },
    [theme.breakpoints.down("md")]: {
        paddingBottom: 50,
        maxWidth: 520,
        alignItems: "center"
    },
    [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
    }
}));
const ExploreBody = styled(ContentBody)(({theme}) => ({
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        textAlign: "center"
    }
}));
const IconWrapper = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    marginTop: 50,
    justifyContent: "center"
}));
